import "./footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the Social Icons
import { faFacebook, faInstagram, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"

export default function Footer() {
    return (
        <footer className="relative bg-white text-black pt-md-20">
            <div className="w-100 gap-12 d-flex flex-column justify-content-between align-items-center">
                <div style={{ width: "83.33%" }} className="d-flex flex-wrap flex-md-row justify-content-between align-items-baseline align-items-md-start cursor-pointer pt-4 py-md-12 border-top">

                    <div className="w-50 medium-width d-flex flex-column py-2 py-md-4 gap-4">
                        <h2 className="text-md font-bold ">
                            DEFY
                        </h2>
                        <div className="d-flex flex-column align-items-start gap-4 mt-md-2 text-xs md:text-md">
                            <a href="https://www.pravaig.com/#discoverDEFY" className="hover:text-orange text-slate-600">
                                <p >
                                    Discover DEFY
                                </p>
                            </a>
                            <a href="mailto:inquiry@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    Fleet
                                </p>
                            </a>
                            <a href="//pravaig.com/defyspecs">
                                <p className="hover:text-orange text-slate-600">
                                    Tech Specs
                                </p>
                            </a>
                            {/* <Link href="/reserve">
                                <p className="hover:text-orange text-slate-600">
                                    Reserve Now
                                </p>
                            </Link> */}
                        </div>
                        <div className="w-50 medium-width d-flex flex-column py-2 py-md-4 gap-4">
                            <h2 className="text-md font-bold">
                                INTERCEPTOR
                            </h2>
                            <div className="d-flex flex-column align-items-start gap-4 mt-md-2 text-xs">
                                <a href="https://www.pravaig.com/interceptorspecs">
                                    <p className="hover:text-orange text-slate-600">
                                        Discover INTERCEPTOR
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="w-50 medium-width d-flex flex-column align-items-end align-items-md-start py-2 py-md-4 gap-4">
                        <h2 className="text-md font-bold">
                            COMPANY
                        </h2>
                        <div className="d-flex flex-column align-items-end align-items-md-start gap-4 mt-md-2 text-xs text-md-sm">
                            <a href="https://www.pravaig.com/company">
                                <p className="hover:text-orange text-slate-600">
                                    About
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/battery">
                                <p className="hover:text-orange text-slate-600">
                                    Batteries
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/company/#lifeAtPravaig">
                                <p className="hover:text-orange text-slate-600">
                                    Life at Pravaig
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/locations">
                                <p className="hover:text-orange text-slate-600">
                                    Locations
                                </p>
                            </a>
                            <a href="https://pravaig.kekahire.com/">
                                <p className="hover:text-orange text-slate-600">
                                    Careers
                                </p>
                            </a>


                        </div>

                    </div>
                    <div className="w-50 medium-width d-flex flex-column align-items-start py-2 py-md-4 gap-4 mt-4 mt-md-0">
                        <h2 className="text-md font-bold">
                            CONTACT
                        </h2>
                        <div className="d-flex flex-column align-items-start gap-4 mt-md-2 text-xs text-md-sm">
                            <a href="mailto:reservations@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    Reservations
                                </p>
                            </a>
                            <a href="mailto:defence@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    Defence
                                </p>
                            </a>
                            <a href="mailto:scm@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    Suppliers
                                </p>
                            </a>
                            <a href="mailto:sm@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    Media
                                </p>
                            </a>
                            <a href="mailto:inquiry@pravaig.com">
                                <p className="hover:text-orange text-slate-600">
                                    General
                                </p>
                            </a>
                            {/* <Link href="/events">
                                <p className="hover:text-orange text-slate-600">
                                    Events
                                </p>
                            </Link>
                            <Link href="/media">
                                <p className="hover:text-orange text-slate-600">
                                    Media Room
                                </p>
                            </Link> */}
                        </div>
                    </div>

                    <div className="w-50 medium-width d-flex flex-column align-items-end align-items-md-start py-2 py-md-4 gap-4">
                        <h2 className="text-md font-bold">
                            LEGAL
                        </h2>
                        <div className="d-flex flex-column align-items-end align-items-md-start gap-4 mt-md-2 text-xs text-md-sm">
                            <a href="https://www.pravaig.com/legal#purchase">
                                <p className="hover:text-orange text-slate-600">
                                    Purchase Agreement
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/legal#privacy">
                                <p className="hover:text-orange text-slate-600">
                                    Privacy Policy
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/legal#warranty">
                                <p className="hover:text-orange text-slate-600">
                                    Warranty Policy
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/legal#cookie">
                                <p className="hover:text-orange text-slate-600">
                                    Cookie Policy
                                </p>
                            </a>
                            <a href="https://www.pravaig.com/#faq" className="text-black">
                                <h2 className="hover:text-orange text-black text-md font-bold">
                                    FAQS
                                </h2>
                            </a>
                        </div>
                        {/* <Link href="/company">
                                <p>
                                Charging Policy
                                </p>
                            </Link>
                            <Link href="/company">
                                <p>
                                Service Policy
                                </p>
                            </Link> */}
                    </div>
                </div>

                <div style={{ width: "83.33%" }} className=" d-flex flex-column flex-md-row gap-2 gap-md-4 justify-content-between align-items-center align-items-md-baseline bg-white text-black pb-4">
                    <div className="w-100 f-2-1 d-flex flex-column justify-content-center align-items-center d-md-block">
                        <p className="font-display font-light text-xs text-center text-md-left mb-0">
                            Pravaig Dynamics Pvt. Ltd.
                        </p>
                    </div>
                    <div className="w-100 f-2-1 d-flex flex-column gap-2 gap-md-4 flex-md-row align-items-center align-items-md-baseline justify-conter-center">
                        <div className="w-100 d-none d-lg-flex justify-content-center align-items-center py-2">
                            <div className="d-flex justify-content-between align-items-center gap-8 cursor-pointer" >
                                <a href="https://www.instagram.com/pravaigdynamics/">
                                    <FontAwesomeIcon icon={faInstagram} size="lg" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://www.linkedin.com/company/42769459/admin/">
                                    <FontAwesomeIcon icon={faLinkedinIn} size="lg" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://twitter.com/pravaigdynamics">
                                    <FontAwesomeIcon icon={faTwitter} size="lg" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://www.facebook.com/pravaig">
                                    <FontAwesomeIcon icon={faFacebook} size="lg" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://www.youtube.com/c/PRAVAIG">
                                    <FontAwesomeIcon icon={faYoutube} size="lg" className="hover:text-orange mx-2 text-icon" />
                                </a>
                            </div>
                        </div>

                        <div className="d-flex d-lg-none justify-content-center align-items-center  py-2">
                            <div className="w-fit gap-4 cursor-pointer" >
                                <a href="https://www.instagram.com/pravaigdynamics/">
                                    <FontAwesomeIcon icon={faInstagram} size="sm" className="hover-text-primary mx-2 text-icon" />
                                </a>
                                <a href="https://www.linkedin.com/company/42769459/admin/">
                                    <FontAwesomeIcon icon={faLinkedinIn} size="sm" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://twitter.com/pravaigdynamics">
                                    <FontAwesomeIcon icon={faTwitter} size="sm" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://www.facebook.com/pravaig">
                                    <FontAwesomeIcon icon={faFacebook} size="sm" className="hover:text-orange mx-2 text-icon" />
                                </a>
                                <a href="https://www.youtube.com/c/PRAVAIG">
                                    <FontAwesomeIcon icon={faYoutube} size="sm" className="hover:text-orange mx-2 text-icon" />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="w-100 f-2-1 d-flex flex-column justify-content-center align-items-center d-md-block">
                        <p className="font-display font-light text-xs text-md-right">
                            Pravaig © 2022. All rights reserved.
                        </p>
                    </div>

                </div>
            </div>
        </footer>
    )
};
