import React, { useEffect, useState } from "react";
// import "./index.css";
import copy from "copy-to-clipboard";
import { useParams } from "react-router-dom";
import { Element } from "react-scroll";

function SummaryId() {
 
  const [fetchedData, setFetchedData] = useState({addons:[],isActive:false,objectDetail:{},addonsArray:[],summaryData:"defy",selected:null,countQuantity:1,modelDetail:null,addonsList:[],total:0,isActiveSumm:false,defyTotal:3950000,defyFleetTotal:3950000,net:0,text:""});
   useEffect(() => { 
    fetchupData()
  },[])
  const carbonCodes = ["C1", "C2", "C3", "C4"];
    const { id } = useParams();
    async function fetchupData() {
      
      let url = `https://configurator.pravaig.com/api/uniquecodes/${id}`;
      let options = {
        method: "GET",
      };
      const response = await fetch(url, options);
      if (response.ok !== true) {
        window.location.href = "/";
      }
      const data = await response.json();
      const dataObj={
        addons:data.addons,
        isActive:data.isActive,
        objectDetail:data.objectDetail,
        addonsArray:data.addonsArray,
        summaryData:data.summaryData,
        selected:data.selected,
        countQuantity:data.countQuantity,
        modelDetail:data.modelDetail,
        addonsList:data.addonsList,
        total:data.total,
        isActiveSumm:data.isActiveSumm,
        net:data.net,
        defyTotal:data.defyTotal,
        defyFleetTotal:data.defyFleetTotal,
        text:data.text
      }
      setFetchedData(dataObj);
    }

  return (
    <>
      <Element name="summary" className="">
        <div className="summaryContainer container">
          <h2 className="main-text">
            {" "}
            {fetchedData.summaryData == "defy" ? "DEFY" : "DEFY FOR FLEET"}
          </h2>
          <div className="car-content">
            <h3> INR {fetchedData.summaryData == "defy" ? fetchedData.defyTotal : fetchedData.defyFleetTotal} </h3>
          </div>
          <div className="desktop-tb summarymbl1">
            <div className="car-summary">
              <h3>SUMMARY</h3>
            </div>
            <div className="table-responsive" style={{ overflowX: "auto" }}>
              <table className="table summarytable">
                <thead className="main-heading">
                  <tr>
                    <td scope="col" className="same-head">
                      Description
                    </td>
                    <td scope="col" className="same-head">
                      No.
                    </td>
                    <td className="same-head"></td>
                    <td scope="col" className="same-head">
                      Price
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <td scope="col" className="same-head">
                      Price
                    </td>
                  </tr>
                </thead>
                <tbody className="same-lines">
                  <tr>
                    <td scope="">Base Price {fetchedData.summaryData}</td>
                    <td>{fetchedData.modelDetail?.code}</td>
                    <td></td>
                    <td>INR {fetchedData.modelDetail?.price}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <td scope="col" className="same-head">
                      Exterior Colour
                    </td>
                  </tr>
                </thead>
                <tbody className="same-lines">
                  <tr>
                    <td scope="">
                      {carbonCodes.includes(fetchedData.selected?.code)
                        ? ""
                        : fetchedData.objectDetail?.exteriorcolors?.name}
                    </td>
                    <td>
                      {carbonCodes.includes(fetchedData.selected?.code)
                        ? ""
                        : fetchedData.objectDetail?.exteriorcolors?.code}
                    </td>
                    <td></td>
                    <td>
                      INR{" "}
                      {carbonCodes.includes(fetchedData.selected?.code)
                        ? ""
                        : fetchedData.objectDetail?.exteriorcolors?.price}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <td scope="col" className="same-head">
                      Wheels
                    </td>
                  </tr>
                </thead>
                <tbody className="same-lines">
                  <tr>
                    <td scope="">{fetchedData.objectDetail?.wheel?.name}</td>
                    <td>{fetchedData.objectDetail?.wheel?.code}</td>
                    <td></td>
                    <td>INR {fetchedData.objectDetail?.wheel?.price || 0}</td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <td scope="col" className="same-head">
                      Interior Colour
                    </td>
                  </tr>
                </thead>
                <tbody className="same-lines">
                  <tr>
                    <td scope="">{fetchedData.objectDetail?.interiorcolors?.color}</td>
                    <td>{fetchedData.objectDetail?.interiorcolors?.code}</td>
                    <td></td>
                    <td>INR {fetchedData.objectDetail?.interiorcolors?.price || 0}</td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <td scope="col" className="same-head">
                      Add Ons
                    </td>
                  </tr>
                </thead>
                <tbody className="same-lines">
                  {fetchedData.addonsList.length === 0 ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td> </td>
                      <td>INR 0</td>
                    </tr>
                  ) : (
                    fetchedData.addonsList.map((addons, i) => (
                      <tr key={i}>
                        <td>
                          {addons.code == "O5" || addons.code == "O6"
                            ? "Maintenance Package "
                            : null}
                          {addons.code == "O3" || addons.code == "O4"
                            ? "Connectivity Package "
                            : null}
                          {addons.title}
                        </td>
                        <td>{addons.code}</td>
                        <td> </td>
                        <td>
                          INR{" "}
                          {addons?.price
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    ))
                  )}
                  {/* <td>INR {total.toLocaleString('en-IN')}</td> */}
                  {/* <div>
    {
      addonsList.map((addons) => (
        <td scope="">{addons.title} </td>
      ))
    }
  </div>

  <div>
    {
      addonsList.map((addons) => (
        <td scope="">{addons.title} </td>
      ))
    }
  </div> */}
                </tbody>

                <thead
                  className={`${
                    fetchedData.isActive ? "unitAdd" : "unitremove "
                  }  same-lines`}>
                  <tr>
                    <td scope="col" className="same-head pt-3 pb-3">
                      Units
                    </td>
                    <td scope="col">
                      <div className="counterMain d-flex justify-content-center">
                        <span className="iconTxt">{fetchedData.countQuantity}</span>
                      </div>
                    </td>
                    <td></td>

                    <td scope="col" className="text-right fleet">
                      {`${fetchedData.countQuantity}  X  INR`}{" "}
                      {(
                        fetchedData.net +
                        fetchedData.total +
                        parseFloat(fetchedData.modelDetail?.price.split(",").join(""))
                      ).toLocaleString("en-IN")}
                    </td>
                  </tr>
                </thead>

                <thead className="last-head">
                  <tr>
                    <td scope="col" className="same-head">
                      Total Price
                    </td>
                    <td></td>
                    <td></td>
                    <td scope="col" className="same-head">
                      INR <span style={{ marginLeft: 2 }}></span>
                      {fetchedData.summaryData == "defy" ? fetchedData.defyTotal : fetchedData.defyFleetTotal}
                      {/* INR {(net + total + parseFloat(modelDetail?.price.split(",").join(""))).toLocaleString('en-IN')} */}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <div className="pdfSection summarymbl2">
            <div className="pdf1">
              <h3>
                Unique code:
                <span
                  onClick={() => {
                    copy(urlOfSite + fetchedData.text);
                    setIsActiveSumm(true);
                    //document.getElementById("copied").innerHTML = "  Copied!"
                  }}>
                  {fetchedData.text}
                  <img
                    src={require("../../Assets/img/pdf.png")}
                    className="w-20"
                  />
                  <span
                    id="copied"
                    className={`${fetchedData.isActiveSumm ? "blockNone" : ""} `}>
                    {" "}
                    Copied!{" "}
                  </span>
                </span>
              </h3>
              {/* <a className="stdBtn">Download PDF</a> */}
            </div>
            {/* <div className="pdf2">
<h4>Share via: </h4>
<div className="social">
<ul className="social-icons">
  <li>
    <a href="#)">
      <i className="fab fa-twitter"></i>
    </a>
  </li>
  <li>
    <a href="#)">
      <i className="fab fa-facebook"></i>
    </a>
  </li>
  <li>
    <a href="#)">
      <i className="fab fa-instagram"></i>
    </a>
  </li>
  <li>
    <a href="#)">
      <i className="fab fa-linkedin"></i>
    </a>
  </li>
  <li>
    <a href="#)">
      <i className="fab fa-youtube"></i>
    </a>
  </li>
</ul>
</div>
</div> */}
          </div>
        </div>
      </Element>
    </>
  );
}

export default SummaryId;
