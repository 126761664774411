export const data =  {
    "addons": {
    "basic": [
    {
    "title": "Cocoon Package",
    "price": "8,50,000",
    "description": "This add-on is designed to give you a business class feel and adds an extra sense of luxury to the DEFY,; includes: <br> 2 x Business Cocoon Seats w/extended calf support <br> Convenience Package - INR 1.20 L <br> Cleaning Kit w/vacuum <br> Cooler <br> Umbrella <br> Magnetic Torch",
    "code": "A1",
    "_id": "63e3942b63c33633607657ef"
    },
    {
    "title": "Convenience Package",
    "price": "1,20,000",
    "description": "Cleaning Kit with vacuum <br> Cooler <br> Umbrella <br> Magnetic Torch",
    "code": "A2",
    "_id": "63e3942b63c33633607657f0"
    },
    {
    "title": "Scenting Package",
    "price": "51,000",
    "description": "This interior add-on is designed to create a pleasant, inviting atmosphere inside the car, leaving passengers feeling refreshed and at ease during their journey. The scenting package incorporates a range of carefully selected aromas based on different moods. So why not add a little extra comfort to your ride with a scenting package?",
    "code": "A3",
    "_id": "63e3942b63c33633607657f1"
    },
    {
    "title": "Heating Package",
    "price": "1,50,000",
    "description": "All 4 heated seats keep you & the passengers feeling warm and relaxed, no matter how long the journey takes. (note: all 4 ventilated seats are included in the standard package)",
    "code": "A4",
    "_id": "63e3942b63c33633607657f2"
    },
    {
    "title": "Luxury Thick Pile Carpet",
    "price": "51,000",
    "description": "Their soft, comfortable feel, colors and styles, allow clients to match their carpets to the interior of their vehicles. Additionally, fluffy carpets can help to improve the car's interior insulation.",
    "code": "A5",
    "_id": "63e3942b63c33633607657f3"
    },
    {
    "title": "Thick Vegan Leather",
    "price": "8,000",
    "description": "It is an excellent choice for those who want to add a luxurious touch to the interior of their car. Thick vegan leather is a breathable man-made material that looks and feels like real leather, but without the use of any animal products. It is an eco-friendly option that is becoming increasingly popular for its durability and affordability.",
    "code": "A6",
    "_id": "63e3942b63c33633607657f4"
    },
    {
    "title": "Wheel Aero Covers",
    "price": "15,000",
    "description": "Wheel aero covers are an add-on, that helps improve the range of the DEFY. They are designed to reduce the drag around the wheel area and help DEFY get more range on a single charge. These covers are typically made of lightweight, aerodynamic materials and can be easily installed on the wheels.",
    "code": "A7",
    "_id": "63e3942b63c33633607657f5"
    },
    {
    "title": "Tyre Quick Fix",
    "price": "35,000",
    "description": "A tyre repair kit is an add-on for your car that allows you to fix tyres on the go. It consists of a pump, patches, and instructions, and it can be very handy in an emergency situation.",
    "code": "A8",
    "_id": "63e3942b63c33633607657f6"
    },
    {
    "title": "Home Fast Charger",
    "price": "45,000",
    "description": "none",
    "code": "A9",
    "_id": "63e3942b63c33633607657f7"
    },
    {
    "title": "PPF + Ceramic",
    "price": "20,000",
    "description": "Paint protection film and ceramic coating work together to protect the surface of your car from all types of harmful outdoor elements(scratches and chips caused by road debris, etc). Applying a ceramic coating over the top of PPF provides better sun protection. UV rays can cause paint protection film to deteriorate gradually over time",
    "code": "O1",
    "_id": "63e3942b63c33633607657f8"
    },
    {
    "title": "Soft Close Doors",
    "price": "80,000",
    "description": "none",
    "code": "02",
    "_id": "63e3942b63c33633607657f9"
    }
    ],
    "advance": [
    {
    "title": "Connectivity Package",
    "subTitle": "1 Year Included",
    "description": "Pravaig DEFY is more than just a powerful electric SUV. It's also smart and connected. This means you can connect to the internet and your mobile phone to maximize the value and convenience of your ride. Connected services include ease of navigation, access to new features and improvements via over-the-air upgrades, and access to vehicle information on your mobile phone via an app. Our Connectivity package offers onboard navigation, location tracking, charge monitoring, and more. While sitting on the sofa you can keep an eye on your DEFY. It’s an ecosystem that helps to connect you, your device, and your DEFY.",
    "options": [
    {
    "title": "+ 2 Years",
    "price": "69,000",
    "description": "Provides unlimited access for a total of 3 years.",
    "code": "03",
    "_id": "63e3942b63c33633607657fb"
    },
    {
    "title": "+ 4 Years",
    "price": "1,08,000",
    "description": "Provides unlimited access for a total of 5 years. ",
    "code": "04",
    "_id": "63e3942b63c33633607657fc"
    }
    ],
    "_id": "63e3942b63c33633607657fa"
    },
    {
    "title": "Maintenance Package",
    "subTitle": "1 Year Included",
    "description": "Take advantage of the Pravaig maintenance package for the ultimate peace of mind. This extended maintenance program begins after your usual 1-year complimentary service expires but it can be extended up to a further 4-years for a small fee. Additionally, it includes a host of benefits for a worry-free time with your DEFY. There is no better way to maintain your DEFY than this. Say goodbye to ongoing service costs with the Pravaig Maintenance Packages by paying a defined cost of maintenance. Benefit from this offer with no hidden costs, speedy turnaround times, and low wait times.",
    "options": [
    {
    "title": "+ 2 Years",
    "price": "3,60,000",
    "description": "Provides unlimited access for a total of 3 years.",
    "code": "05",
    "_id": "63e3942b63c33633607657fe"
    },
    {
    "title": "+ 4 Years",
    "price": "6,30,000",
    "description": "Provides unlimited access for a total of 5 years.",
    "code": "06",
    "_id": "63e3942b63c33633607657ff"
    }
    ],
    "_id": "63e3942b63c33633607657fd"
    }
    ]
    },
    "_id": "63e3942b63c33633607657df",
    "models": [
    {
    "car": "defy for fleet",
    "price": "39,50,000",
    "code": "DF",
    "_id": "63e3942b63c33633607657e0"
    },
    {
    "car": "defy",
    "price": "39,50,000",
    "code": "DF",
    "_id": "63e3942b63c33633607657e1"
    }
    ],
    "exteriorcolors": [
    {
    "name": "Kaziranga Green",
    "color": "#003D0E",
    "mainImage": "upload/image_1672916403764.jpg",
    "wheels": [
    "upload/image_1672916617966.jpg",
    "upload/image_1672916886898.jpg",
    "upload/image_1672916920012.jpg"
    ],
    "price": "0",
    "code": "E1",
    "_id": "63e3942b63c33633607657e2"
    },
    {
    "name": "Ultraviolet Purple",
    "color": "#2a105b",
    "mainImage": "upload/image_1672918095469.jpg",
    "wheels": [
    "upload/image_1672918127689.jpg",
    "upload/image_1672918147729.jpg",
    "upload/image_1672918165244.jpg"
    ],
    "price": "0",
    "code": "E2",
    "_id": "63e3942b63c33633607657e3"
    },
    {
    "name": "Siachen Blue",
    "color": "#003395",
    "mainImage": "upload/image_1672917276194.jpg",
    "wheels": [
    "upload/image_1672917303279.jpg",
    "upload/image_1672917322600.jpg",
    "upload/image_1672917342901.jpg"
    ],
    "price": "0",
    "code": "E3",
    "_id": "63e3942b63c33633607657e4"
    },
    {
    "name": "Sunbeam Yellow",
    "color": "#fcb300",
    "mainImage": "upload/image_1672917463164.jpg",
    "wheels": [
    "upload/image_1672917507764.jpg",
    "upload/image_1672917525898.jpg",
    "upload/image_1672917542519.jpg"
    ],
    "price": "0",
    "code": "E4",
    "_id": "63e3942b63c33633607657e5"
    },
    {
    "name": "Shadow Black",
    "color": "#000000",
    "mainImage": "upload/image_1672917627463.jpg",
    "wheels": [
    "upload/image_1672917669115.jpg",
    "upload/image_1672917685901.jpg",
    "upload/image_1672917705995.jpg"
    ],
    "price": "0",
    "code": "E5",
    "_id": "63e3942b63c33633607657e6"
    },
    {
    "name": "Anti flash white",
    "color": "#f2f3f4",
    "mainImage": "upload/image_1672918202962.jpg",
    "wheels": [
    "upload/image_1672918221279.jpg",
    "upload/image_1672918264376.jpg",
    "upload/image_1672918281268.jpg"
    ],
    "price": "0",
    "code": "E6",
    "_id": "63e3942b63c33633607657e7"
    },
    {
    "name": "Space Grey",
    "color": "#8d918d",
    "mainImage": "upload/image_1672918904947.jpg",
    "wheels": [
    "upload/image_1672918987147.jpg",
    "upload/image_1672919005743.jpg",
    "upload/image_1672919023006.jpg"
    ],
    "price": "0",
    "code": "E7",
    "_id": "63e3942b63c33633607657e8"
    },
    {
    "name": "Lithium Silver",
    "color": "#AAA9AD",
    "mainImage": "upload/image_1672918417021.jpg",
    "wheels": [
    "upload/image_1672918448514.jpg",
    "upload/image_1672918465385.jpg",
    "upload/image_1672918482137.jpg"
    ],
    "price": "0",
    "code": "E8",
    "_id": "63e3942b63c33633607657e9"
    },
    {
    "name": "Vermillion Red",
    "color": "#ef0000",
    "mainImage": "upload/image_1672918512034.jpg",
    "wheels": [
    "upload/image_1672918535226.jpg",
    "upload/image_1672918573880.jpg",
    "upload/image_1672918588561.jpg"
    ],
    "price": "0",
    "code": "E9",
    "_id": "63e3942b63c33633607657ea"
    }
    ],
    "interiorcolors": [
    {
    "_id": "63e387f2cd075625e7fc8ff8",
    "color": "Interior Black",
    "mainImage": "upload/images_1674050811844.jpg",
    "price": "0",
    "code": "I1",
    "__v": 0
    },
    {
    "_id": "63e38828cd075625e7fc8ffa",
    "color": "Interior Grey",
    "mainImage": "upload/images_1674050811863.jpg",
    "price": "0",
    "code": "I2",
    "__v": 0
    },
    {
    "_id": "63e3884fcd075625e7fc8ffc",
    "color": "Interior tan",
    "mainImage": "upload/images_1674050811870.jpg",
    "price": "0",
    "code": "I3",
    "__v": 0
    },
    {
    "_id": "63e38ad9cd075625e7fc90cb",
    "color": "Interior White",
    "mainImage": "upload/images_1674050811896.jpg",
    "price": "0",
    "code": "I4",
    "__v": 0
    }
    ],
    "carbonedition": [
    {
    "color": "upload/image_1672919211987.jpg",
    "mainImage": "upload/image_1672919058711.jpg",
    "wheels": [
    "upload/image_1672919086253.jpg",
    "upload/image_1672919100773.jpg",
    "upload/image_1672919117336.jpg"
    ],
    "price": "6300000",
    "code": "C1",
    "_id": "63e3942b63c33633607657eb"
    },
    {
    "color": "upload/image_1672919270686.jpg",
    "mainImage": "upload/image_1672919288009.jpg",
    "wheels": [
    "upload/image_1672919317457.jpg",
    "upload/image_1672919334357.jpg",
    "upload/image_1672919352724.jpg"
    ],
    "price": "6300000",
    "code": "C2",
    "_id": "63e3942b63c33633607657ec"
    },
    {
    "color": "upload/image_1672919383424.jpg",
    "mainImage": "upload/image_1672919409841.jpg",
    "wheels": [
    "upload/image_1672919451405.jpg",
    "upload/image_1672919467738.jpg",
    "upload/image_1672919481760.jpg"
    ],
    "price": "6300000",
    "code": "C3",
    "_id": "63e3942b63c33633607657ed"
    },
    {
    "color": "upload/image_1672919502488.jpg",
    "mainImage": "upload/image_1672919521361.jpg",
    "wheels": [
    "upload/image_1672919536071.jpg",
    "upload/image_1672919579457.jpg",
    "upload/image_1672919594165.jpg"
    ],
    "price": "6300000",
    "code": "C4",
    "_id": "63e3942b63c33633607657ee"
    }
    ],
    "wheels": [
    {
    "_id": "63e38714cd075625e7fc8f8e",
    "mainImage": "upload/image_1672920611303.png",
    "name": "Standard",
    "size": "18 inch",
    "price": "0",
    "code": "W1",
    "__v": 0
    },
    {
    "_id": "63e3879bcd075625e7fc8ff4",
    "mainImage": "upload/image_1672920638133.png",
    "name": "20 inches",
    "size": "20 inch",
    "price": "1,50,000",
    "code": "W2",
    "__v": 0
    },
    {
    "_id": "63e387afcd075625e7fc8ff6",
    "mainImage": "upload/image_1672920655279.png",
    "name": "20 inches",
    "size": "20 inch",
    "price": "1,50,000",
    "code": "W3",
    "__v": 0
    }
    ],
    "__v": 0
    }