import React, { useState,useEffect } from "react";
// import "./slider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { UseSlider } from "../../Hooks/UseSlider";
import { useParams, useSearchParams } from "react-router-dom";


const Slider = () => {
  const [slideData,setSlideData]=useState({})
  const [sliderNum,setSliderNUm]=useState(0);
  
  let url = `https://configurator.pravaig.com/api/`;
  const { id } = useParams();
    async function fetchupDataA() {
      
      let url = `https://configurator.pravaig.com/api/uniquecodes/${id}`;
      let options = {
        method: "GET",
      };
      const response = await fetch(url, options);
      if (response.ok !== true) {
        window.location.href = "/";
      }
      const data = await response.json();
      if(data?.selectedCars[0]?.key ==="interiorcolors"){
        setSliderNUm(1);
      }
      setSlideData(data);
    }
  
    useEffect(() => {
    fetchupDataA()
    }, [])
  return (
    <div>
      <div className="car-slider">

        <Carousel
          selectedItem={sliderNum}
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
        >
          {slideData?.selectedCars?.map((img, id) => {
            return <img key={id} src={url + img?.value} alt="" />;
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Slider;
