 import request from "./Instance";
import axios, { AxiosResponse } from "axios";
import { data } from "../utils/utils";
const Services = {
  async getAll() {

    const res = await request.get("cars/");
    // const res = data;
  
    return res;
  },
};

export default Services;
