import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import HomeId from "./Pages/IdHome";
import { SliderProvider } from "./Context/SliderContext";


function App() {
  return (
      <SliderProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/:id" element={<HomeId />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </SliderProvider>
  );
}

export default App;
